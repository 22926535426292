import React, { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { communicatorsType, communicatorsTypePl, localStorageKey } from '../../../utils/constants';
import theme from '../../../themes/theme';

const useStyles = makeStyles({
    cellsContainer: {
        borderBottom: 'none',
        display: 'flex',
        flexDirection: 'column',
        background: '#f5f5f5',
        padding: '.7rem 0',
        border: `1px solid ${theme.colors.border}`,
    },
    cellRow: {
        display: 'flex',
        flexWrap: 'wrap',
        marginLeft: 20,
    },
    cell: {
        display: 'flex',
        fontSize: '.9rem',
        padding: 0,
        margin: 5,
        marginRight: 50,
    },
    cellNumbers: {
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'auto',
    },
    cellNumber: {
        margin: 0,
    },
});

const ListConfigHeader = (props, ref) => {
    const { cell, cellRow, cellsContainer, cellNumbers, cellNumber } = useStyles();

    const setListConfigHeader = () => {
        const configList = JSON.parse(localStorage.getItem(props.storageKey));

        const getSelectedWaterpipeName = JSON.parse(
            localStorage.getItem(localStorageKey.WATERPIPES_LIST)
        ).filter(waterpipesList => waterpipesList.id === configList.waterpipeId)[0]?.name;

        function getCommunicatorTransaltion(comunicatorType) {
            if (comunicatorType === communicatorsType.COUPLED_COMMUNICATOR) {
                return communicatorsTypePl.COUPLED_COMMUNICATOR;
            }
            if (comunicatorType === communicatorsType.COMMUNICATOR) {
                return communicatorsTypePl.COMMUNICATOR;
            }
            if (comunicatorType === communicatorsType.CONCENTRATOR) {
                return communicatorsTypePl.CONCENTRATOR;
            }

            return 'komunikator nieznany';
        }

        return {
            waterpipe: getSelectedWaterpipeName,
            waterpipeSign: configList.waterpipeSign,
            datesRangeFrom: configList.dateFrom,
            datesRangeTo: configList.dateTo,
            communicatorType: getCommunicatorTransaltion(configList.type),
            communicatorsFrom: configList.numbersFrom,
            communicatorsTo: configList.numbersTo,
            communicatorsList: configList.numbers,
            favoriteReportName: configList.selectedFavoriteReport?.name,
            channel: configList.channel,
        };
    };

    const setListOfNumbers = () => {
        if (setListConfigHeader().communicatorsList) {
            const communicatorsNumbers = setListConfigHeader().communicatorsList.split(',');

            return communicatorsNumbers.map((item, index) => {
                if (communicatorsNumbers.length === index + 1) {
                    return (
                        <p className={cellNumber} key={index}>
                            {item}
                        </p>
                    );
                }
                return (
                    <p className={cellNumber} key={index}>
                        {item},
                    </p>
                );
            });
        }

        return null;
    };

    return (
        <section className={cellsContainer} ref={ref}>
            {setListConfigHeader().favoriteReportName && (
                <div className={cellRow}>
                    <div className={cell}>
                        <b>ULUBIONE ZESTAWIENIE:&nbsp;</b>
                        {setListConfigHeader().favoriteReportName}
                    </div>
                </div>
            )}
            <div className={cellRow}>
                <div className={cell}>
                    <b>WODOCIĄG:&nbsp;</b>
                    {setListConfigHeader().waterpipe}{' '}
                    {setListConfigHeader().waterpipeSign ? `(${setListConfigHeader().waterpipeSign})` : ''}
                </div>
                <div className={cell}>
                    <b>OD:&nbsp;</b>
                    {setListConfigHeader().datesRangeFrom} &nbsp;
                    <b>DO:&nbsp;</b>
                    {setListConfigHeader().datesRangeTo}
                </div>
                {setListConfigHeader().channel ? (
                    <div className={cell}>
                        <b>KANAŁ:&nbsp;</b>
                        {setListConfigHeader().channel}
                    </div>
                ) : (
                    <div className={cell}>
                        <b>TYP:&nbsp;</b>
                        {setListConfigHeader().communicatorType}
                    </div>
                )}
                {setListConfigHeader().communicatorsFrom && (
                    <div className={cell}>
                        <b>OD:&nbsp;</b> {setListConfigHeader().communicatorsFrom}&nbsp;
                        <b>DO:&nbsp;</b>
                        {setListConfigHeader().communicatorsTo}
                    </div>
                )}
                {setListConfigHeader().communicatorsList && (
                    <div className={cell}>
                        <b>LISTA NUMERÓW:&nbsp;</b>
                        <div className={cellNumbers}>{setListOfNumbers()}</div>
                    </div>
                )}
            </div>
        </section>
    );
};

export default forwardRef(ListConfigHeader);
