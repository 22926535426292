import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const styles = () => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: 'whitesmoke',
        padding: '30px',
        border: 'none',
        width: '80vw',
    },
});

function PopupModal({
    classes,
    liveObjData: { isOfflinePackageFound, text, isCheckClicked } = {
        liveObjData: {
            isOfflinePackageFound: false,
            text: '',
            isCheckClicked: false,
        },
    },
    handleResetPackageInLiveObject,
    protocolV11DecryptedData,
    handleClearProtocolV11DecryptedData,
}) {
    const [open, setOpen] = useState(false);
    const [isError, setIsError] = useState(false);
    const [modalText, setModalText] = useState('');

    const handleClose = () => {
        setOpen(false);
        setModalText('');
        handleClearProtocolV11DecryptedData();
    };

    const setModalHeader = () => {
        if (!modalText) {
            return '';
        }
        if (protocolV11DecryptedData) {
            return 'Pakiet zdekodowany:';
        }
        return isError ? 'Problem z odnalezieniem pakietu:' : 'Odnaleziony pakiet offline:';
    };

    useEffect(() => {
        if (protocolV11DecryptedData) {
            setOpen(true);
            setModalText(protocolV11DecryptedData);
        }
    }, [protocolV11DecryptedData]);

    useEffect(() => {
        if (isCheckClicked && text) {
            setOpen(true);
            setIsError(!isOfflinePackageFound);
            setModalText(text);
            handleResetPackageInLiveObject();
        }
    }, [text, isOfflinePackageFound, isCheckClicked]);

    return (
        <>
            <Modal
                className={classes.modal}
                open={open}
                disablePortal={true}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={open}>
                    <div className={classes.paper}>
                        <Typography variant="h5" gutterBottom color={isError ? 'error' : 'textPrimary'}>
                            {setModalHeader()}
                        </Typography>
                        <p
                            style={{
                                display: 'block',
                                overflowWrap: 'break-word',
                                maxWidth: '100%',
                            }}>
                            {modalText}
                        </p>
                    </div>
                </Fade>
            </Modal>
        </>
    );
}

export default withStyles(styles)(PopupModal);
